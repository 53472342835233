import React from 'react'
import PropTypes from 'prop-types'
import '../scss/Cta-button.scss'
import { Link } from 'gatsby'
import { ArrowRight } from '../icons/ArrowRight'

const CTAInner = ({
  buttonClass,
  bgColor,
  text,
  leftIcon,
  rightIcon,
  rightCircle
}) => {
  return (
    <div
      className={`cta-button-wrapper d-flex align-items-center${
        buttonClass ? ' ' + buttonClass : ''
      }`}
    >
      <div
        className="d-flex cta-button align-items-center justify-content-between"
        style={{ background: bgColor }}
      >
        {leftIcon && (
          <div className="cta-left-icon">
            <img src={leftIcon} alt="go" width={21} />
          </div>
        )}
        <div className="cta-text">{text}</div>
        {rightIcon && (
          <div className="cta-right-icon">
            <ArrowRight />
          </div>
        )}
      </div>
      {rightCircle && (
        <div className="cta-right-circle">
          <ArrowRight />
        </div>
      )}
    </div>
  )
}

export default function CTAButton(props) {
  const {
    text,
    buttonClass = 'dark',
    bgColor,
    link,
    leftIcon,
    type = 'cta',
    rightIcon = true,
    rightCircle = false
  } = props

  const innerProps = {
    buttonClass,
    bgColor,
    text,
    leftIcon,
    rightIcon,
    rightCircle
  }

  return (
    <div>
      {type === 'cta' && (
        <Link className="cta-button-link" to={link}>
          <CTAInner {...innerProps} />
        </Link>
      )}
      {type === 'mail' && (
        <a className="cta-button-link" href={link} target="_blank">
          <CTAInner {...innerProps} />
        </a>
      )}
      {(type === 'button' || type === 'submit') && (
        <button className="cta-button-link cta-as-button" type={type}>
          <CTAInner {...innerProps} />
        </button>
      )}
    </div>
  )
}

CTAButton.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
  link: PropTypes.string,
  rightIcon: PropTypes.bool,
  rightCircle: PropTypes.bool,
  leftIcon: PropTypes.string
}
