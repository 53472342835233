import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './koble/Footer'
import Navbar from './koble/Navbar'
import './all.sass'
import './scss/generic.scss'
import './styles.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()
  let showHeaderFooter = true
  let updateNavbarContent = false
  if (children.props.signupInfo || children.props.loginInfo) {
    showHeaderFooter = false
  } else if (children.props.pageName) {
    updateNavbarContent = true
  }

  return (
    <div className="Koble-ui">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.png`}
        />
      </Helmet>
      {showHeaderFooter && <Navbar updateNavbarContent={updateNavbarContent} />}
      <div>{children}</div>
      {showHeaderFooter && <Footer />}
    </div>
  )
}

export default Layout
