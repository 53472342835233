import React from 'react'

export const ArrowRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0778 7.58026L7.31491 1.81739L8.37557 0.756734L15.4194 7.80053L15.9497 8.33086L15.4194 8.86119L8.37521 15.9053L7.31455 14.8447L13.079 9.08026L0.800288 9.08027L0.800287 7.58027L13.0778 7.58026Z"
      fill="currentColor"
    />
  </svg>
)
