import React, { useState } from 'react'
import { Link } from 'gatsby'
import logo from '../../img/logo.svg'
import '../scss/navbar.scss'
import CTAButton from '../common/CTAButton'

const Navbar = (props) => {
  const [active, setActive] = useState(false)

  const toggleHamburger = () => {
    setActive(!active)
  }

  const navBarActiveClass = active ? 'is-active' : ''

  const isUpdateContent = props.updateNavbarContent
  let buttonText = 'Get the newsletter'
  let link = 'https://newsletter.koble.ai/subscribe'
  let loginUrl = 'https://investor.koble.ai/login'
  if (isUpdateContent) {
    buttonText = 'Get my score'
    link = 'https://app.koble.ai/founderfit/signup'
    loginUrl = 'https://app.koble.ai/founderfit/login'
  }
  return (
    <nav
      className="navbar is-transparent max-1024-container"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container header">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Koble" style={{ width: '104px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            role="menuitem"
            tabIndex={0}
            onKeyPress={() => toggleHamburger()}
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          {!isUpdateContent && (
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item route-link" to="/how-it-works">
                <span>How it works</span>
              </Link>
              <Link className="navbar-item route-link" to="/about-us">
                <span>Who we are</span>
              </Link>
              <Link className="navbar-item route-link" to="/investors">
                <span>Investors</span>
              </Link>
            </div>
          )}

          <div className="navbar-end has-text-centered has-cta-button">
            {/* <Link className="navbar-item route-link" to={loginUrl}>
              <span>Sign in</span>
            </Link> */}
            <CTAButton text={buttonText} link={link} buttonClass="light" />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
