import * as React from 'react'

import '../scss/footer.scss'

import logo from '../../img/logo.svg'
import { Link } from 'gatsby'
import Word from './Word'

const Footer = class extends React.Component {
  render() {
    const title = [
      {
        cssClass: 'oval',
        text: 'Re-engineering'
      },
      {
        cssClass: 'oval',
        text: 'startup'
      },
      {
        cssClass: 'diamond',
        text: 'investing'
      },
      {
        cssClass: 'rectangle',
        text: 'with'
      },
      {
        cssClass: 'diamond',
        text: 'AI'
      }
    ]

    const contact = 'hello@koble.ai'

    return (
      <div className="koble-footer">
        <div className="footer-container">
          <div className="d-md-flex">
            <div className="left">
              <div className="mt-md-4">
                <img src={logo} alt="Koble" />
              </div>
              <div className="contact">
                <a href={`mailto:${contact}`} target="_blank">
                  {contact}
                </a>
              </div>
              <div className="social-icons d-flex">
                <Link className="" to="https://www.facebook.com/KobleTeam">
                  <img
                    className="me-3"
                    src="/img/koble/Facebook.png"
                    alt="Koble"
                  />
                </Link>
                <Link className="" to="https://twitter.com/KobleTeam">
                  <img
                    className="me-3"
                    src="/img/koble/Twitter.png"
                    alt="Koble"
                  />
                </Link>
                <Link
                  className=""
                  to="https://www.linkedin.com/company/kobleteam"
                >
                  <img
                    className="me-3"
                    src="/img/koble/LinkedIN.png"
                    alt="Koble"
                  />
                </Link>
              </div>
            </div>
            <div className="right">
              <div className="styled-text d-flex">
                {title.map((item) => (
                  <Word key={item.text} {...item} />
                ))}
              </div>

              <div className="d-md-flex fs-14 btm-links">
                <div className="w-m-100 btm-left">
                  <div className="d-flex">
                    <div className="w-100">
                      <Link className="" to="/how-it-works">
                        <div className="bd-b-1 py-3">How it works</div>
                      </Link>
                      <Link className="" to="/about-us">
                        <div className="bd-b-1 py-3">Who we are</div>
                      </Link>
                      <Link className="" to="/investors">
                        <div className="bd-b-1 py-3">Investors</div>
                      </Link>
                      <a href="https://newsletter.koble.ai/subscribe">
                        <div className="bd-b-1 py-3">Get the newsletter</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-md-center copy-right-section fs-14 flex-wrap">
            <div className="pe-4 copyright">
              © {new Date().getFullYear()} Koble. All rights reserved
            </div>
            <Link className="" to="/privacy-policy">
              <div className="ms-md-4 me-4 me-md-3 link-text">
                Privacy policy
              </div>
            </Link>
            <Link className="" to="/terms-of-service">
              <div className="link-text">Terms of service</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
